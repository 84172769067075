import {Button}       from 'antd';
import {AuthLayout}   from 'features/auth/auth-layout';
import {useAuth0}     from '@auth0/auth0-react';
import {TenantSelect} from 'foundations/tenancy/tenant-select';

export function Login() {
    const {loginWithRedirect, isLoading} = useAuth0();

    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <TenantSelect/>
            </div>
            <Button block type={'primary'} onClick={() => loginWithRedirect()} loading={isLoading}>{'Sign In'}</Button>
        </AuthLayout>
    );
}
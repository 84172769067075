import {useGlobalState} from 'foundations/state/use-global-state';
import {Select}         from 'antd';
import {Tenancy}        from '@boomhealth/tenancy';

export function TenantSelect() {
    const [tenant, setTenant] = useGlobalState('tenant');

    const onChange = (tenant: string) => {
        localStorage.setItem('tenant', tenant);
        setTenant(tenant);
        window.location.reload();
    };

    return (
        <Select onChange={onChange} value={tenant}
                style={{width: '100%'}}>
            {
                Tenancy.all().map((tenant) =>
                    <Select.Option key={tenant.id} value={tenant.id}>{tenant.id}</Select.Option>
                )
            }
        </Select>
    );
}
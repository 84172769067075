import React           from 'react';
import ReactDOM        from 'react-dom/client';
import {App}           from 'app';
import './index.css';
import {Tenancy}       from '@boomhealth/tenancy';
import {Auth0Provider} from '@auth0/auth0-react';

Tenancy.init(localStorage.getItem('tenant') ?? 'boomhealthae');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={Tenancy.current.AUTH0_DOMAIN}
            clientId={Tenancy.current.AUTH0_CLIENT_IDS.BACKHOUSE}
            useRefreshTokens
            cacheLocation={'localstorage'}
            authorizationParams={{
                audience:     'core-api',
                redirect_uri: window.location.origin
            }}
        >
            <App/>
        </Auth0Provider>
    </React.StrictMode>
);
